<template>
    <div>
        <template v-if="fields">
            <v-row
                v-for="field in fields"
                class="my-7"
            >
                <v-col cols="12" md="4" class="pb-0">
                    <div class="text-subtitle-2">{{ field.name }}</div>
                </v-col>
                <v-col cols="12" md="8">
                    <v-textarea
                        v-model="field.newValue"
                        outlined
                        hide-details
                        auto-grow
                        row-height="28"
                        rows="2"
                    ></v-textarea>
                    <v-btn
                        @click="saveField(field)"
                        small
                        color="success"
                        class="mt-4"
                        :disabled="field.withoutTags == field.newValue || role == 'ghost'"
                        :loading="field.loading"
                    >
                        Сохранить
                    </v-btn>
                </v-col>
            </v-row>

            <v-dialog
                v-if="questionnaire.status == 'filling'"
                v-model="finishDialog"
                max-width="400"
            >
                <template #activator="{on, attrs}">
                    <v-btn
                        v-if="role!='minor'"
                        :disabled="role=='ghost'"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        class="mb-2"
                    >
                        Отдать в работу
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title style="word-break:break-word;">
                        Передать ответы в техцентр?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn
                            color="success"
                            :loading="loading"
                            @click="finish"
                        >
                            Отправить
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            outlined
                            @click="finishDialog=false"
                        >
                            Отмена
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <template v-if="questionnaire.status == 'finished'">
                <div
                    class="font-italic primary--text">
                    Проверено и передано в работу Ответственным лицом {{ questionnaire['finished_at_formatted'] }}
                </div>
                <div>
                    <v-dialog
                        v-model="refillDialog"
                        max-width="400"
                    >
                        <template #activator="{on, attrs}">
                            <v-btn
                                v-if="role!='minor'"
                                :disabled="role=='ghost'"
                                v-bind="attrs"
                                v-on="on"
                                color="grey"
                                class="my-3"
                                outlined
                                small
                            >
                                Перезаполнить
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title style="word-break:break-word;">
                                Запросить изменение данных анкеты?
                            </v-card-title>
                            <v-card-actions>
                                <v-btn
                                    color="success"
                                    :loading="loading"
                                    @click="refill"
                                >
                                    Запросить
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="error"
                                    outlined
                                    @click="refillDialog=false"
                                >
                                    Отмена
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </template>

            <div
                v-if="questionnaire.status == 'refill'"
                class="font-italic mb-3 primary--text"
            >
                Запрос на изменение данных <span class="font-weight-bold">{{ questionnaire['title'] }}</span> по проекту
                <span class="font-weight-bold">{{ login }}</span> получен, ожидайте
            </div>
        </template>

        <v-alert
            v-else-if="error"
            type="error"
        >
            {{ error }}
        </v-alert>

        <v-progress-linear
            v-else
            indeterminate
            color="green"
        ></v-progress-linear>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {

    props: ['questionnaire', 'panel', 'index'],

    created() {
        this.formFields();
    },

    data: () => ({
        fields: null,
        error: null,
        loading: false,
        finishDialog: false,
        refillDialog: false
    }),

    watch: {
        panel() {
            if (this.panel == this.index)
                this.formFields();
        },
    },

    computed: {
        ...mapState({
            login: state => state.main.login,
            role: state => state.common.user.role
        })
    },

    methods: {

        ...mapActions(['request', 'alert']),

        formFields() {
            this.fields = null;
            this.error = null;
            return this.request({
                'module': 'Workspace',
                'controller': 'Questionnaire',
                'action': 'collect',
                'id': this.questionnaire['id'],
                'type': this.questionnaire['type']
            }).then(response => {
                this.fields = [];
                for (let field of response)
                    this.fields.push({
                        ...field,
                        loading: false,
                        newValue: field.withoutTags
                    });
            }).catch(() => {
                this.error = 'Не удалось получить список вопросов';
            });
        },

        saveField(field) {
            field.loading = true;
            return this.request({
                'module': 'Workspace',
                'controller': 'Questionnaire',
                'action': 'save',
                'id': this.questionnaire['id'],
                'type': this.questionnaire['type'],
                'item': {
                    'id': field.id,
                    'value': field.newValue,
                    'name': field.name,
                    'type': field.type
                }
            }).then(() => {
                this.alert({
                    'text': 'Ответ сохранён',
                    'color': 'success',
                    'timeout': 2222
                });
            }).catch(() => {
                this.alert({
                    'text': 'Что-то пошло не так',
                    'color': 'error'
                });
            }).finally(() => {
                field.loading = false;
            });
        },

        finish() {
            this.loading = true;
            return this.request({
                'module': 'Workspace',
                'controller': 'Questionnaire',
                'action': 'finish',
                'id': this.questionnaire['id'],
                'type': this.questionnaire['type']
            }).then(() => {
                this.$emit('updated');
            }).catch(() => {
                this.alert({
                    'text': 'Что-то пошло не так',
                    'color': 'error'
                });
            }).finally(() => {
                this.loading = false;
                this.finishDialog = false;
            });
        },

        refill() {
            this.loading = true;
            return this.request({
                'module': 'Workspace',
                'controller': 'Questionnaire',
                'action': 'refill',
                'id': this.questionnaire['id'],
                'type': this.questionnaire['type']
            }).then(() => {
                this.$emit('updated');
            }).catch(() => {
                this.alert({
                    'text': 'Что-то пошло не так',
                    'color': 'error'
                });
            }).finally(() => {
                this.loading = false;
            });
        }

    }

}
</script>