<template>
    <div>
        <template v-if="fields">
            <v-row
                v-for="field in fields"
                class="my-7"
            >
                <v-col cols="12" md="4" class="pb-0">
                    <div class="text-subtitle-2">{{ field.name }}</div>
                </v-col>
                <v-col cols="12" md="8">
                    <div class="text-subtitle-1">{{ field.newValue }}</div>
                </v-col>
            </v-row>
        </template>

        <v-alert
            v-else-if="error"
            type="error"
        >
            {{ error }}
        </v-alert>

        <v-progress-linear
            v-else
            indeterminate
            color="green"
        ></v-progress-linear>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {

    props: ['questionnaire', 'panel', 'index'],

    created() {
        this.formFields();
    },

    data: () => ({
        fields: null,
        error: null,
        loading: false,
        finishDialog: false,
        refillDialog: false
    }),

    watch: {
        panel() {
            if (this.panel == this.index)
                this.formFields();
        },
    },

    computed: {
        ...mapState({
            login: state => state.main.login,
            role: state => state.common.user.role
        })
    },

    methods: {

        ...mapActions(['request', 'alert']),

        formFields() {
            this.fields = null;
            this.error = null;
            return this.request({
                'module': 'Workspace',
                'controller': 'Questionnaire',
                'action': 'collect',
                'id': this.questionnaire['id'],
                'type': this.questionnaire['type']
            }).then(response => {
                this.fields = [];
                for (let field of response)
                    this.fields.push({
                        ...field,
                        loading: false,
                        newValue: field.withoutTags
                    });
            }).catch(() => {
                this.error = 'Не удалось получить список вопросов';
            });
        }

    }

}
</script>