var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.essences)?[_vm._l((_vm.essences),function(essence,name){return _c('v-row',{staticClass:"my-2 my-md-7"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-medium text-subtitle-1"},[_vm._v(_vm._s(name))])]),_c('v-col',{class:{'py-1 py-md-3': !!essence.newVariant, 'pb-0  pt-1 pt-md-3': !essence.newVariant},attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"text-caption"},[_c('v-form',{ref:name + 'Form',refInFor:true,model:{value:(essence.valid),callback:function ($$v) {_vm.$set(essence, "valid", $$v)},expression:"essence.valid"}},[_c('v-select',{class:{'mb-2': !!essence.newVariant},staticStyle:{"font-size":"16px"},attrs:{"items":essence.variants,"rules":[function () { return !!essence.newVariant || 'Необходимо выбрать вариант'; }],"label":"Вариант ответа","single-line":"","dense":"","hide-details":!!essence.newVariant},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1 dd--select-item"},[_vm._v(_vm._s(item))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1 dd--select-item"},[_vm._v(_vm._s(item))])]}}],null,true),model:{value:(essence.newVariant),callback:function ($$v) {_vm.$set(essence, "newVariant", $$v)},expression:"essence.newVariant"}})],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-textarea',{attrs:{"label":"Источник контента (ссылка или короткий комментарий)","outlined":"","hide-details":"","auto-grow":"","row-height":"28","rows":"2"},model:{value:(essence.newValue),callback:function ($$v) {_vm.$set(essence, "newValue", $$v)},expression:"essence.newValue"}}),_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","color":"success","disabled":_vm.role == 'ghost',"loading":essence.loading},on:{"click":function($event){return _vm.saveEssence(essence, name)}}},[_vm._v(" Сохранить ")])],1)],1)}),(_vm.questionnaire.status == 'filling')?_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.role!='minor')?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 mb-2",attrs:{"disabled":_vm.role=='ghost',"color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Отдать в работу ")]):_vm._e()]}}],null,false,4010147224),model:{value:(_vm.finishDialog),callback:function ($$v) {_vm.finishDialog=$$v},expression:"finishDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Передать ответы в техцентр? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.finish}},[_vm._v(" Отправить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.finishDialog=false}}},[_vm._v(" Отмена ")])],1)],1)],1):_vm._e(),(_vm.questionnaire.status == 'finished')?[_c('div',{staticClass:"font-italic primary--text"},[_vm._v(" Проверено и передано в работу Ответственным лицом "+_vm._s(_vm.questionnaire['finished_at_formatted'])+" ")]),_c('div',[_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.role!='minor')?_c('v-btn',_vm._g(_vm._b({staticClass:"my-3",attrs:{"disabled":_vm.role=='ghost',"color":"grey","outlined":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Перезаполнить ")]):_vm._e()]}}],null,false,3945522816),model:{value:(_vm.refillDialog),callback:function ($$v) {_vm.refillDialog=$$v},expression:"refillDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Запросить изменение данных анкеты? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.refill}},[_vm._v(" Запросить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.refillDialog=false}}},[_vm._v(" Отмена ")])],1)],1)],1)],1)]:_vm._e(),(_vm.questionnaire.status == 'refill')?_c('div',{staticClass:"font-italic mb-3 primary--text"},[_vm._v(" Запрос на изменение данных "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.questionnaire['title']))]),_vm._v(" по проекту "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.login))]),_vm._v(" получен, ожидайте ")]):_vm._e()]:(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"green"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }