<template>
    <v-container>
        <div class="bg"></div>

        <div class="mt-4 mb-4">
            <v-card>
                <v-card-text>
                    <v-breadcrumbs
                        :items="breadcrumbs"
                        class="pl-0 pt-3"
                        large
                    >
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item
                                v-if="item.id === null"
                                icon
                                @click="currentFolder = null"
                                :disabled="!currentFolder"
                                :style="!currentFolder ? '' : 'cursor:pointer;'"
                            >
                                <v-icon
                                    :color="!currentFolder ? 'grey lighten-1' : 'grey darken-1'"
                                    size="32"
                                >
                                    mdi-home
                                </v-icon>
                            </v-breadcrumbs-item>
                            <v-breadcrumbs-item
                                v-else
                                @click="currentFolder = folders.find(folder => folder.id == item.id)"
                                :disabled="item.disabled"
                                text
                                style="text-transform: none;"
                                :style="item.disabled ? '' : 'cursor:pointer;'"
                            >
                                <div style="word-break: break-all;">
                                    {{ item.text }}
                                </div>
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-row class="my-0">
                        <v-col
                            v-for="folder in currentFolders"
                            cols="12" md="6" lg="4" xl="3"
                        >
                            <div
                                @click="currentFolder=folder"
                                class="workspace-folder d-inline-block"
                            >
                                <div class="workspace-folder--icon">
                                    <v-icon left size="26">mdi-folder</v-icon>
                                </div>
                                <div class="text-subtitle-2 pl-8 workspace-folder--text">
                                    <div class="mr-2" style="word-break:break-all;">
                                        {{ folder.name }}
                                        <div
                                            v-if="folder.filling_count"
                                            class="workspace-folder__status-count d-inline-block"
                                            style="width:20px"
                                        >
                                            <v-badge
                                                right
                                                offset-x="0"
                                                color="purple lighten-1"
                                                :content="folder.filling_count"
                                            ></v-badge>
                                        </div>
                                        <div
                                            v-if="folder.info_count"
                                            class="workspace-folder__status-count d-inline-block"
                                            style="width:20px"
                                        >
                                            <v-badge
                                                right
                                                offset-x="0"
                                                color="blue-grey"
                                                :content="folder.info_count"
                                            ></v-badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col v-if="currentFolder" cols="12">
                            <div class="text-h5">{{ currentFolder.name }}</div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>

        <progress-bar
            v-for="progressBar in progressBars"
            :key="progressBar.id"
            :progress="progressBar"
            class="my-5"
        ></progress-bar>

        <v-expansion-panels
            v-model="panel"
            class="my-5"
        >
            <v-expansion-panel
                v-for="(questionnaire, index) in questionnaires"
                :key="index"
            >
                <v-expansion-panel-header>
                    <div class="text-h6 questionnaire-title flex-grow-0">
                        <span class="questionnaire-title-text">{{ questionnaire.title }}</span>
                    </div>
                    <div class="px-4 px-md-10 questionnaire-status" :class="questionnaire.status_color">
                        {{ questionnaire.status_print }}
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <info-handbook
                        v-if="questionnaire.type == 'infoHandbook'"
                        :questionnaire="questionnaire"
                        :panel="panel"
                        :index="index"
                    ></info-handbook>
                    <handbook
                        v-if="questionnaire.type == 'handbook'"
                        :questionnaire="questionnaire"
                        :panel="panel"
                        :index="index"
                        @updated="updated()"
                    ></handbook>
                    <default-development
                        v-if="questionnaire.type == 'defaultDevelopment'"
                        :questionnaire="questionnaire"
                        :panel="panel"
                        :index="questionnaire.id"
                        @updated="updated()"
                    ></default-development>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import handbook from '@/components/workspace/Handbook'
import infoHandbook from '@/components/workspace/InfoHandbook'
import defaultDevelopment from '@/components/workspace/DefaultDevelopment'
import progressBar from '@/components/workspace/ProgressBar'

export default {

    components: {
        handbook,
        infoHandbook,
        defaultDevelopment,
        progressBar
    },

    data: () => ({
        panel: null,
        currentFolder: null,
        questionnaires: [],
        progressBars: []
    }),

    created() {
        if (!this.modules.includes('Workspace')) {
            if (this.modules.includes('Chat'))
                this.$router.push('/chat')
            else
                this.$router.push('/home')
        }
        this.initWorkspace();
    },

    watch: {
        currentFolder() {
            this.initWorkspace();
        },

        clientId() {
            this.currentFolder = null;
            this.initWorkspace();
        },

        progressUpdatedAt() {
            this.formProgressBars();
        }
    },

    computed: {
        ...mapState({
            clientId: state => state.main.clientId,
            folders: state => state.workspace.questionnaireFolders,
            handbooks: state => state.workspace.handbooks,
            infoHandbooks: state => state.workspace.infoHandbooks,
            defaultDevelopments: state => state.workspace.defaultDevelopments,
            progress: state => state.workspace.progress,
            modules: state => state.main.modules,
            gettingProgressCycle: state => state.workspace.gettingProgressCycle,
            progressUpdatedAt: state => state.workspace.progressUpdatedAt
        }),

        breadcrumbs() {
            let breadcrumbs = [];
            if (this.currentFolder) {
                breadcrumbs.unshift({
                    id: this.currentFolder.id,
                    folder_id: this.currentFolder.folder_id,
                    text: this.currentFolder.name,
                    disabled: true
                });
                while (breadcrumbs[0].folder_id) {
                    let folder = this.folders.find(folder => folder.id == breadcrumbs[0].folder_id);
                    breadcrumbs.unshift({
                        id: folder.id,
                        folder_id: folder.folder_id,
                        text: folder.name
                    });
                }
            }
            breadcrumbs.unshift({
                id: null
            });
            return breadcrumbs;
        },

        currentFolders() {
            return this.folders.filter(folder => folder['folder_id'] == this.currentFolderId);
        },

        currentFolderId() {
            return this.currentFolder?.id ?? null;
        }
    },

    methods: {

        ...mapMutations({
            changeGettingProgressCycle: 'gettingProgressCycle'
        }),

        ...mapActions(['refreshWorkspace']),

        initWorkspace() {
            this.panel = null;
            this.formProgressBars();
            this.formQuestionnaires();
            this.getProgress();
            this.$nextTick(() => {
                this.setWidths();
            });
        },

        setWidths() {
            const titles = document.getElementsByClassName('questionnaire-title');
            let maxWidth = 0;
            for (let title of titles) {
                let textWidth = title.querySelector('.questionnaire-title-text').offsetWidth;
                if (textWidth > maxWidth)
                    maxWidth = textWidth;
            }
            for (let title of titles)
                title.style.width = maxWidth + 'px';
        },

        getProgress() {
            if (!this.progress.length) return false;
            if (this.$route.name != 'WorkSpace') return false;
            if (this.gettingProgressCycle) return false;
            this.changeGettingProgressCycle(true);
            return this.$store.dispatch('getProgress')
                .catch(() => {
                })
                .finally(() => {
                    setTimeout(() => {
                        this.changeGettingProgressCycle(false)
                        this.getProgress()
                    }, 60000);
                });
        },

        formProgressBars() {
            this.progressBars = this.progress.filter(progress => progress['folder_id'] == this.currentFolderId);
        },

        formQuestionnaires() {
            this.questionnaires = []
                .concat(this.handbooks.filter(record => record['folder_id'] == this.currentFolderId))
                .concat(this.defaultDevelopments.filter(defaultDevelopment => defaultDevelopment['folder_id'] == this.currentFolderId))
                .concat(this.infoHandbooks.filter(record => record['folder_id'] == this.currentFolderId))
                .map(questionnaire => {
                    if (questionnaire.type == 'infoHandbook')
                        questionnaire.status = 'info';
                    switch (questionnaire.status) {
                        case 'info':
                            questionnaire.status_print = 'Инфо';
                            questionnaire.status_color = 'blue-grey--text';
                            break;
                        case 'filling':
                            questionnaire.status_print = 'Ждём ответа клиента';
                            questionnaire.status_color = 'purple--text text--lighten-1';
                            break;
                        case 'finished':
                            questionnaire.status_print = 'Завершён';
                            questionnaire.status_color = 'green--text';
                            break;
                        case 'refill':
                            questionnaire.status_print = 'Запрос перезаполнить';
                            questionnaire.status_color = 'yellow--text text--darken-4';
                            break;
                    }
                    return questionnaire;
                })
                .sort((a, b) => {
                    if (a.id > b.id) return -1;
                    if (a.id == b.id) return 0;
                    if (a.id < b.id) return 1;
                })
                .sort((a, b) => {
                    if (a.status == b.status) return 0;
                    if (a.status == 'info') return -1;
                    if (b.status == 'info') return 1;
                    if (a.status == 'filling') return -1;
                    if (b.status == 'filling') return 1;
                    if (a.status == 'refill') return -1;
                    if (b.status == 'refill') return 1;
                });
        },

        async updated() {
            await this.refreshWorkspace();
            this.formQuestionnaires();
        }
    }
}
</script>

<style lang="scss">
.workspace-folder {
    cursor: pointer;

    &--icon {
        position: absolute;
    }

    &--text {
        padding-top: 3px;
    }

    &__status-count {
        margin-left: 2px;
    }
}
</style>