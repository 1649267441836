<template>
    <v-card :class="{'red accent-1': brakeText}">
        <v-card-title>
            {{ progress.title }}
        </v-card-title>
        <v-card-text>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="green"
            ></v-progress-linear>
            <div v-else>
                <v-row>
                    <v-col cols="12">
                        <div class="bar">
                            <div class="bar__item grey" ref="queue"></div>
                            <div class="bar__item purple lighten-1" ref="wait"></div>
                            <div class="bar__item blue" ref="work"></div>
                            <div class="bar__item green" ref="finish"></div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="auto">
                        В очереди:
                        <v-badge
                            inline
                            color="grey"
                            class="mr-2"
                            :content="queue"
                        ></v-badge>
                    </v-col>
                    <v-col cols="12" md="auto">
                        Ждём ответа клиента:
                        <v-badge
                            inline
                            color="purple lighten-1"
                            class="mr-2"
                            :content="wait"
                        ></v-badge>
                    </v-col>
                    <v-col cols="12" md="auto">
                        В работе:
                        <v-badge
                            inline
                            color="blue"
                            class="mr-2"
                            :content="work"
                        ></v-badge>
                    </v-col>
                    <v-col cols="12" md="auto">
                        Выполненные:
                        <v-badge
                            inline
                            color="green"
                            class="mr-2"
                            :content="finish"
                        ></v-badge>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div
                            v-if="brakeText"
                            v-html="brakeText"
                            class="black-color text-subtitle-1 font-weight-medium"
                        ></div>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapActions} from 'vuex'

export default {

    props: ['progress'],

    data: () => ({
        queue: '0',
        wait: '0',
        work: '0',
        finish: '0',
        brakeText: '',
        loading: false
    }),

    watch: {
        progress() {
            this.formProgressBar();
        }
    },

    created() {
        this.formProgressBar();
    },

    methods: {
        ...mapActions(['request']),

        formProgressBar() {
            if (this.progress.planfix_data) {
                this.loading = false;
            } else {
                this.loading = true;
                return false;
            }
            this.$nextTick(() => {
                let planfixData = JSON.parse(this.progress.planfix_data);
                this.queue = String(planfixData.queue);
                this.wait = String(planfixData.wait);
                this.work = String(planfixData.work);
                this.finish = String(planfixData.finish);
                this.brakeText = planfixData.brake_text;
                let amount = planfixData.queue + planfixData.work + planfixData.wait + planfixData.finish;
                this.$refs.queue.style.width = planfixData.queue / amount * 100 + '%';
                this.$refs.wait.style.width = planfixData.wait / amount * 100 + '%';
                this.$refs.work.style.width = planfixData.work / amount * 100 + '%';
                this.$refs.finish.style.width = planfixData.finish / amount * 100 + '%';
            });
        }
    }

}
</script>

<style lang="scss">

.bar {
    width: 100%;
    height: 13px;

    &__item {
        display: inline-block;
        height: 13px;
    }
}

</style>